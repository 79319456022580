import React from "react";

function Wrapper({ children }) {
  const style = {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    transform: "translate(-50%,-50%)",
  };
  return <div style={style}>{children}</div>;
}

export default Wrapper;
