import { createContext, useContext } from "react";
import { useReactMediaRecorder } from "react-media-recorder";

export const RecorderContext = createContext();

export const useRecorderContext = () => useContext(RecorderContext);

export const RecorderProvider = ({ children }) => {
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });

  return (
    <RecorderContext.Provider
      value={{
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
        clearBlobUrl,
      }}
    >
      {children}
    </RecorderContext.Provider>
  );
};
