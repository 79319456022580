import React, { useRef, useEffect } from "react";
import CircleButton from "../CircleButton";
import ReactPlayer from "react-player";
import PauseIcon from "../assets/pause.png";
import PlayIcon from "../assets/play.png";
import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import { useSearchParams } from "react-router-dom";
import { Text, Spinner } from "@chakra-ui/react";
import Wave from "../Wave";
import { useNavigate } from "react-router-dom";

function Play({ firebaseApp }) {
  const storage = getStorage(firebaseApp);
  let [searchParams, setSearchParams] = useSearchParams();
  let [downloadUrl, setDownloadUrl] = React.useState("");
  let [ready, setReady] = React.useState(false);
  let [playing, setPlaying] = React.useState(false);

  let player = useRef(null);
  const navigate = useNavigate();

  const filename = searchParams.get("f");

  const storageRef = ref(
    storage,
    "recordings/launch/" + searchParams.get("f") + ".wav"
  );

  useEffect(() => {
    getDownloadURL(storageRef).then((url) => {
      setDownloadUrl(url);
    });
  }, []);

  useEffect(() => {
    player.current.seekTo(0);
  }, [player]);

  useEffect(() => {
    if (filename == "" || filename == null) {
      navigate("/");
    }
  }, [filename]);

  function pauseSound() {
    if (player.current) {
      setPlaying(false);
    }
  }
  function playSound() {
    if (player.current) {
      setPlaying(true);
    }
  }
  function getElapsedTime() {
    if (player.current) {
      /* return millisToMinutesAndSeconds(
        player.current.played * player.current.duration
      ); */
      return player.current.played;
    }
  }
  function handlePlayPause() {
    if (player.current) {
      playing ? pauseSound() : playSound();
    }
  }
  function handleEnded() {
    if (player.current) {
      setPlaying(false);
      player.current.seekTo(0);
    }
  }
  return (
    <>
      <Text color="white" fontSize="4xl">
        There is a message for you!
      </Text>
      {!ready ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="transparent"
          color="white"
          size="xl"
          m={16}
        />
      ) : (
        <>
          <Wave playing={playing} opacity={playing ? 1 : 0.5}></Wave>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircleButton
              background="rgba(255,255,255,0.2)"
              onClick={handlePlayPause}
            >
              {playing ? (
                <img height="50" width="40" src={PauseIcon}></img>
              ) : (
                <img
                  style={{ marginLeft: "8px" }}
                  height="50"
                  width="40"
                  src={PlayIcon}
                ></img>
              )}
            </CircleButton>
          </div>
        </>
      )}

      <ReactPlayer
        audio
        ref={player}
        width={(window.innerWidth / 4) * 3}
        height={0}
        playing={playing}
        url={downloadUrl}
        onEnded={handleEnded}
        playsinline
        onReady={() => setReady(true)}
        volume={1}
        muted={false}
      />
    </>
  );
}

export default Play;
