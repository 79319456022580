import React, { useEffect } from "react";
import Lottie from "react-lottie-player";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

import lottieJson from "./assets/wave_white.json";

const Wave = ({ opacity, playing }) => {
  const [width, setWidth] = React.useState(0);
  useEffect(() => {
    const w = (window.innerWidth / 5) * 4;
    w >= 800 ? setWidth(800) : setWidth(w);
  }, [window.innerWidth]);

  return (
    <Lottie
      play={playing}
      loop
      animationData={lottieJson}
      style={{
        width,
        height: width / 3,
        opacity,
        margin: "0 auto",
      }}
    />
  );
};

export default Wave;
