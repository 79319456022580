import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { RecorderProvider } from "./hooks/useRecorder";
import { RecordingProvider } from "./hooks/useRecording";

const root = ReactDOM.createRoot(document.getElementById("root"));
document.body.style.overflow = "hidden";
root.render(
  <RecorderProvider>
    <React.StrictMode>
      <ChakraProvider>
        <RecordingProvider>
          <App />
        </RecordingProvider>
      </ChakraProvider>
    </React.StrictMode>
  </RecorderProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
