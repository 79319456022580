import React, { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import bg from "./assets/bg.png";
import lockLogo from "./assets/lock_logo.png";
import { initializeApp } from "@firebase/app";
import { getStorage, getDownloadURL, ref } from "@firebase/storage";
import ReactPlayer from "react-player";
import { useRef } from "react";
import PauseIcon from "./assets/pause.png";
import PlayIcon from "./assets/play.png";
import Wave from "./Wave.jsx";
import Wrapper from "./Wrapper";
import Record from "./pages/Record";
import Play from "./pages/Play";
import Thanks from "./pages/Thanks";
import ThanksCard from "./pages/ThanksCard";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA9WXBXyLjMf_nNaPiL9p2X_Js9T4mB518",
  authDomain: "thelock-91512.firebaseapp.com",
  projectId: "thelock-91512",
  storageBucket: "thelock-91512.appspot.com",
  messagingSenderId: "383662882228",
  appId: "1:383662882228:web:02f803f26018b4544086e1",
  measurementId: "G-8Z5VPPGLV0",
  databaseURL: "https://thelock-91512-default-rtdb.firebaseio.com/",
};

function App() {
  /*   const [permissionGiven, setPermissionGiven] = useState(false);
  const permissions = navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  permissions
    .then((stream) => {
      console.log("accepted the permissions");
      setPermissionGiven(true);
    })
    .catch((err) => {
      setPermissionGiven(false);
      console.log(`${err.name} : ${err.message}`);
    }); */

  const firebaseApp = initializeApp(firebaseConfig);
  return (
    <div className="App">
      <img
        src={bg}
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          position: "absolute",
          zIndex: "-1",
          top: "0",
          left: "0",
        }}
      />

      <Wrapper>
        <img
          src={lockLogo}
          style={{
            position: "relative",
            width: "200px",
            margin: "0 auto 60px auto",
            //top: "60px",
          }}
        />
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={<Record firebaseApp={firebaseApp}></Record>}
            />
            <Route
              path="play"
              element={<Play firebaseApp={firebaseApp}></Play>}
            />
            <Route
              path="thanks"
              element={<Thanks firebaseApp={firebaseApp}></Thanks>}
            />
          </Routes>
        </BrowserRouter>
      </Wrapper>
    </div>
  );
}

export default App;
