import React from "react";

const CircleButton = function ({ children, onClick, background = "white" }) {
  const style = {
    backgroundColor: background,
    borderRadius: "50px",
    width: "100px",
    height: "100px",
    fontSize: "30px",
    padding: "20px 20px",
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div style={style} onClick={onClick}>
      {children}
    </div>
  );
};
export default CircleButton;
