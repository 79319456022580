import React, { useEffect } from "react";

import { useReactMediaRecorder } from "react-media-recorder";
import ReactPlayer from "react-player";
import CircleButton from "../CircleButton";
import PauseIcon from "../assets/pause.png";
import PlayIcon from "../assets/play.png";
import MicIcon from "../assets/mic.png";
import StopIcon from "../assets/stop.png";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getDatabase, ref as DBref, set as DBset } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { Checkbox, Progress } from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Input, Text, Stack } from "@chakra-ui/react";
import Wave from "../Wave";
import SparkMD5 from "spark-md5";
import { useRecorderContext } from "../hooks/useRecorder";
import { useRecordingContext } from "../hooks/useRecording";

function Icon({ status }) {
  if (status === "idle") {
    return <img height="auto" width="40" src={MicIcon}></img>;
  } else if (status === "recording") {
    return <img height="auto" width="auto" src={StopIcon}></img>;
  } else if (status === "stopped") {
    return (
      <img
        style={{ marginLeft: "8px" }}
        height="50"
        width="40"
        src={PlayIcon}
      ></img>
    );
  } else if (status === "playing") {
    return <img height="50" width="40" src={PauseIcon}></img>;
  }
}

function getLocalStream() {
  return navigator.mediaDevices.getUserMedia({ video: false, audio: true });
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

function Record({ firebaseApp }) {
  let [playing, setPlaying] = React.useState(false);
  let [approved, setApproved] = React.useState(false);
  let [privacyConsent, setPrivacyConsent] = React.useState(false);
  let [isPrivacyInvalid, setIsPrivacyInvalid] = React.useState(false);
  let [showProgress, setShowProgress] = React.useState(false);
  let [audioBlobUrl, setAudioBlobUrl] = React.useState(null);
  let [name, setName] = React.useState("");
  let [isNameInvalid, setIsNameInvalid] = React.useState(false);
  let [email, setEmail] = React.useState("");
  let [isEmailInvalid, setIsEmailInvalid] = React.useState(false);
  const [recording, setRecording] = useRecordingContext();
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useRecorderContext();
  const navigate = useNavigate();
  const storage = getStorage(firebaseApp);
  const functions = getFunctions(firebaseApp);
  const database = getDatabase(firebaseApp);

  let player = React.useRef(null);

  React.useEffect(() => {
    console.log("PLAYER REF LOADED");
    player.current.seekTo(0);
  }, [player]);

  function pauseSound() {
    if (player.current) {
      setPlaying(false);
      console.log("Pausing sound");
    }
  }
  function playSound() {
    if (player.current) {
      setPlaying(true);
      console.log("Playing sound");
      console.log("BlobURL" + mediaBlobUrl);
    }
  }
  function getElapsedTime() {
    if (player.current) {
      /* return millisToMinutesAndSeconds(
          player.current.played * player.current.duration
        ); */
      return player.current.played;
    }
  }
  function approveMessage(blobUrl) {
    setApproved(true);
    console.log("MESSAGE APPROVED");
  }
  function generateFileName(blob) {
    const a = new FileReader();
    a.readAsArrayBuffer(blob);
    return blob.arrayBuffer().then((buffer) => {
      return SparkMD5.ArrayBuffer.hash(buffer);
    });
  }
  async function sendMessage(blobUrl) {
    console.log("SENDING MESSAGE");
    setShowProgress(true);
    const audioFile = await fetch(blobUrl);
    const blob = await audioFile.blob();
    const fileName = await generateFileName(blob);
    const filePath = `recordings/launch/${fileName}.wav`;
    const storageRef = ref(storage, filePath);

    setRecording({ blob, fileName });

    if (name == "" || email == "") {
      navigate("/");
      return;
    }

    uploadBytes(storageRef, blob, { contentType: "audio/wav" }).then(
      async (snapshot) => {
        /* console.log("Uploaded a blob or file!");
        console.log(`snapshot: ${JSON.stringify(snapshot)}`); */
        /* getDownloadURL(storageRef).then((url) => {
        }); */
        const sendEmail = httpsCallable(functions, "sendEmail");
        await sendEmail({
          recipientName: name,
          email: email,
          f: fileName,
        })
          .then(async (result) => {
            await DBset(DBref(database, `recordings/${fileName}`), {
              email,
              recipientName: name,
              filePath,
              fileName,
              privacyConsent,
              uploadedAt: new Date().toISOString(),
            });
            return result;
          })
          .then((result) => {
            clearBlobUrl();
            setApproved(false);
            navigate(`/thanks?f=${fileName}`);
          });
      }
    );
  }
  function handlePlayPause(
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl
  ) {
    if (status === "idle") {
      return startRecording;
    } else if (status === "recording") {
      return () => {
        stopRecording();
        setAudioBlobUrl(mediaBlobUrl);
      };
    } else if (status === "stopped") {
      return player.current && playSound;
    } else if (status === "playing") {
      return player.current && pauseSound;
    }
  }

  function handleEnded() {
    if (player.current) {
      setPlaying(false);
      player.current.seekTo(0);
    }
  }

  useEffect(() => {
    if (status === "stopped") {
      setApproved(true);
    }
  }, [status]);

  return (
    <div>
      {!showProgress && !approved ? (
        <Stack alignItems={"center"} justifyContent={"space-between"}>
          {status === "idle" && (
            <Text color="white" fontSize="4xl">
              Press the icon to record a special message
              <br />
              and share with your loved one
            </Text>
          )}
          <Wave
            playing={playing || status == "recording"}
            opacity={playing || status == "recording" ? 1 : 0.5}
          ></Wave>
          <CircleButton
            background={
              status === "recording"
                ? "rgba(255,0,0,0.8)"
                : "rgba(255,255,255,0.2)"
            }
            onClick={handlePlayPause(
              playing ? "playing" : status,
              startRecording,
              stopRecording
            )}
          >
            <Icon status={playing ? "playing" : status}></Icon>
          </CircleButton>
          {status === "stopped" ? (
            <>
              <ButtonGroup variant="outline" spacing="6" paddingTop="60px">
                <Button
                  borderRadius="50px"
                  bg="white"
                  onClick={() => approveMessage(mediaBlobUrl)}
                >
                  I like it!
                </Button>
                <Button
                  borderRadius="50px"
                  bg="white"
                  onClick={() => {
                    clearBlobUrl();
                    setApproved(false);
                  }}
                >
                  Record a new message
                </Button>
              </ButtonGroup>
            </>
          ) : null}
        </Stack>
      ) : approved && !showProgress ? (
        <>
          <Stack alignItems="center" spacing="60px">
            <Stack spacing={3}>
              <Input
                bg="white"
                w={"100%"}
                maxW={"80vw"}
                placeholder="Recipient Name"
                onChange={(e) => setName(e.target.value)}
                size="md"
                isInvalid={isNameInvalid}
              />
              <Input
                bg="white"
                w={"100%"}
                maxW={"80vw"}
                placeholder="Recipient Email"
                onChange={(e) => setEmail(e.target.value)}
                size="md"
                isInvalid={isEmailInvalid}
              />
              <Text maxW={"80vw"} align="left" color="white" fontSize="sm">
                By providing your email address, you consent to receive
                marketing communications from Tiffany & Co.
                <br></br>
                You may unsubscribe from Tiffany & Co. communications at any
                time by contacting Customer Service at
                customerservice@tiffany.com.
                <br></br>
                For more information about how Tiffany & Co. communicates with
                you and use your information, please visit
                <br></br>
                <a
                  href="https://www.tiffany.com/policy/privacy-policy/"
                  target="_blank"
                >
                  tiffany.com/policy/privacy-policy
                </a>
              </Text>
              <Checkbox
                checked={privacyConsent}
                onChange={(e) => {
                  setPrivacyConsent(e.target.checked);
                }}
                isInvalid={isPrivacyInvalid}
              >
                <Text color="white" fontSize="sm">
                  I've read and understood the privacy policy terms
                </Text>
              </Checkbox>
            </Stack>
            <Button
              borderRadius="50px"
              bg="white"
              onClick={() => {
                let shoudReturn = false;
                if (!privacyConsent) {
                  setIsPrivacyInvalid(true);
                  shoudReturn = true;
                } else {
                  setIsPrivacyInvalid(false);
                }
                if (!name) {
                  setIsNameInvalid(true);
                  shoudReturn = true;
                } else {
                  setIsNameInvalid(false);
                }
                if (!validateEmail(email)) {
                  setIsEmailInvalid(true);
                  shoudReturn = true;
                } else {
                  setIsEmailInvalid(false);
                }

                if (shoudReturn) {
                  return;
                }

                sendMessage(mediaBlobUrl);
              }}
            >
              Send my message
            </Button>
            <Button
              borderRadius="50px"
              bg="white"
              onClick={() => {
                setApproved(false);
                clearBlobUrl();
              }}
            >
              Record a new message
            </Button>
          </Stack>
        </>
      ) : (
        <Stack alignItems={"center"} marginX="-20vw">
          <Progress width={"100%"} size="xs" isIndeterminate />
        </Stack>
      )}

      <ReactPlayer
        audio="true"
        ref={player}
        width={0}
        height={0}
        playing={playing}
        url={mediaBlobUrl}
        onEnded={handleEnded}
        playsinline
        volume={1}
        muted={false}
      />
    </div>
  );
}

export default Record;
