import React from "react";
import { Text, Stack, Button } from "@chakra-ui/react";
import Wrapper from "../Wrapper";
import bg_card from "../assets/bg_card.png";
import lockLogo from "../assets/lock_logo.png";
import { QRCodeSVG } from "qrcode.react";

import { useLayoutEffect } from "react";
import { useRecordingContext } from "../hooks/useRecording";

const ThanksCard = React.forwardRef((props, ref) => {
  const [recording, setRecording] = useRecordingContext();
  return (
    <div ref={ref}>
      <style>
        {`@media print
      {
         @page {
          size: auto;
          margin: 0;
         }
      } `}
      </style>

      <img
        src={bg_card}
        style={{
          width: "100%",
        }}
      />
      <QRCodeSVG
        style={{
          width: "120px",
          height: "120px",
          margin: "40px auto 0 auto",
        }}
        size={120}
        fgColor="#232F5D"
        value={`https://private-conversation.com/play?f=${recording.fileName}`}
      />
    </div>
  );
});

export default ThanksCard;
