import React from "react";
import { Text, Stack, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useSearchParams } from "react-router-dom";
import ThanksCard from "./ThanksCard";
import RenderAsImage from "react-render-as-image";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from "qrcode.react";

function Thanks() {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  const cardRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => cardRef.current,
  });

  function printCard() {
    if (window?.webkit?.messageHandlers?.print) {
      window.webkit.messageHandlers.print.postMessage(
        ReactDOMServer.renderToStaticMarkup(
          <RenderAsImage width="500" height="900">
            <ThanksCard />
          </RenderAsImage>
        )
      );
    } else {
      handlePrint();
    }
  }

  return (
    <div>
      <style>
        {`@media print
      {
         @page {
          size: auto;
          margin: 0;
         }
      } `}
      </style>
      <Stack mt="60px" spacing="60px" alignItems="center">
        <Stack w="60vw" mt="60px" spacing={3} alignItems="center">
          <Text color="white" fontSize="4xl">
            Your message has been sent!
          </Text>
          <Text color="white" fontSize="3xl">
            Thank you.
          </Text>
          {/* <QRCodeSVG
            style={{
              width: "120px",
              height: "120px",
              margin: "40px auto 0 auto",
            }}
            size={120}
            fgColor="#232F5D"
            value={`https://private-conversation.com/play?f=${decodeURIComponent(
              params.f
            )}`}
          /> */}
        </Stack>
        <Button borderRadius="50px" bg="white" onClick={() => printCard()}>
          Print my card
        </Button>
        <Button
          onClick={() => {
            navigate("/");
          }}
          borderRadius="50px"
          bg="white"
          maxW="200px"
        >
          Record a new message
        </Button>
      </Stack>
      <div style={{ display: "none" }}>
        <ThanksCard ref={cardRef} />
      </div>
    </div>
  );
}

export default Thanks;
