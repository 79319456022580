import { createContext, useContext, useState } from "react";

export const RecordingContext = createContext();

export const useRecordingContext = () => useContext(RecordingContext);

export const RecordingProvider = ({ children }) => {
  const [{ blob, fileName }, setRecording] = useState({
    blob: null,
    fileName: "",
  });

  return (
    <RecordingContext.Provider
      value={[
        {
          blob,
          fileName,
        },
        setRecording,
      ]}
    >
      {children}
    </RecordingContext.Provider>
  );
};
